import React from 'react';
import Layout from '../../components/common/Layout';
import SEO from '../../components/SEO';
import Container from '../../components/common/Container';

const RenewPage = () => (
  <Layout>
    <SEO
      title="Account Verlängerung - Nextcloud Hosting"
      description="Verlängere deine Portknox Account"
    />
    <Container>
      <h1>Verlängere deinen Portknox Account</h1>

      <form
        action="https://www.paypal.com/cgi-bin/webscr"
        method="post"
        target="_top"
      >
        <input type="hidden" name="cmd" value="_s-xclick" />
        <input
          type="hidden"
          name="hosted_button_id"
          value="DMUJD64MWJTSA"
        />
        <table>
          <tr>
            <td>
              <input
                type="hidden"
                required
                name="on0"
                value="Portknox Account Name"
              />
              Portknox Account Name
            </td>
          </tr>
          <tr>
            <td>
              <input type="text" name="os0" maxLength="200" />
            </td>
          </tr>
        </table>
        <input
          type="image"
          src="https://www.paypalobjects.com/de_DE/DE/i/btn/btn_paynowCC_LG.gif"
          border="0"
          name="submit"
          alt="Jetzt einfach, schnell und sicher online bezahlen – mit PayPal."
        />
        <img
          alt=""
          border="0"
          src="https://www.paypalobjects.com/de_DE/i/scr/pixel.gif"
          width="1"
          height="1"
        />
      </form>
    </Container>
  </Layout>
);

export default RenewPage;
